<template>
  <div class="politiqueContainer">
    <h4>Politique de confidentialité</h4>
    <p>
      Le site web fokus-studio.com est détenu par Fokus Studio, qui est un
      contrôleur de données de vos données personnelles...
    </p>
    <h5>Les informations personnelles que nous collectons :</h5>
    <p>
      Lorsque vous visitez le fokus-studio.com, nous recueillons automatiquement
      certaines informations sur votre appareil, notamment des informations sur
      votre navigateur web, votre adresse IP, votre fuseau horaire et certains
      des cookies installés sur votre appareil. En outre, lorsque vous naviguez
      sur le site, nous recueillons des informations sur les pages web ou les
      produits individuels que vous consultez, sur les sites web ou les termes
      de recherche qui vous ont renvoyé au site et sur la manière dont vous
      interagissez avec le site. Nous désignons ces informations collectées
      automatiquement par le terme "informations sur les appareils". En outre,
      nous pourrions collecter les données personnelles que vous nous fournissez
      (y compris, mais sans s'y limiter, le nom, le prénom, l'adresse, les
      informations de paiement, etc.) lors de l'inscription afin de pouvoir
      exécuter le contrat.
    </p>
    <!-- Continuer avec les autres sections et leurs contenus -->
    <h5>Les informations personnelles que nous collectons :</h5>
    <p>
      Lorsque vous visitez le fokus-studio.com, nous recueillons automatiquement
      certaines informations sur votre appareil, notamment des informations sur
      votre navigateur web, votre adresse IP, votre fuseau horaire et certains
      des cookies installés sur votre appareil. En outre, lorsque vous naviguez
      sur le site, nous recueillons des informations sur les pages web ou les
      produits individuels que vous consultez, sur les sites web ou les termes
      de recherche qui vous ont renvoyé au site et sur la manière dont vous
      interagissez avec le site. Nous désignons ces informations collectées
      automatiquement par le terme "informations sur les appareils". En outre,
      nous pourrions collecter les données personnelles que vous nous fournissez
      (y compris, mais sans s'y limiter, le nom, le prénom, l'adresse, les
      informations de paiement, etc.) lors de l'inscription afin de pouvoir
      exécuter le contrat.
    </p>

    <h5>Pourquoi traitons-nous vos données ?</h5>
    <p>
      Notre priorité absolue est la sécurité des données des clients et, à ce
      titre, nous ne pouvons traiter que des données minimales sur les
      utilisateurs, uniquement dans la mesure où cela est absolument nécessaire
      pour maintenir le site web. Les informations collectées automatiquement
      sont utilisées uniquement pour identifier les cas potentiels d'abus et
      établir des informations statistiques concernant l'utilisation du site
      web. Ces informations statistiques ne sont pas autrement agrégées de
      manière à identifier un utilisateur particulier du système.
    </p>
    <p>
      Vous pouvez visiter le site web sans nous dire qui vous êtes ni révéler
      d'informations, par lesquelles quelqu'un pourrait vous identifier comme un
      individu spécifique et identifiable. Toutefois, si vous souhaitez utiliser
      certaines fonctionnalités du site web, ou si vous souhaitez recevoir notre
      lettre d'information ou fournir d'autres détails en remplissant un
      formulaire, vous pouvez nous fournir des données personnelles, telles que
      votre e-mail, votre prénom, votre nom, votre ville de résidence, votre
      organisation, votre numéro de téléphone. Vous pouvez choisir de ne pas
      nous fournir vos données personnelles, mais il se peut alors que vous ne
      puissiez pas profiter de certaines fonctionnalités du site web. Par
      exemple, vous ne pourrez pas recevoir notre bulletin d'information ou nous
      contacter directement à partir du site web. Les utilisateurs qui ne savent
      pas quelles informations sont obligatoires sont invités à nous contacter
      via loic@fokus-studio.com.
    </p>

    <h5>Vos droits :</h5>
    <p>
      Si vous êtes un résident européen, vous disposez des droits suivants liés
      à vos données personnelles :
    </p>
    <ul>
      <li>Le droit d'être informé.</li>
      <li>Le droit d'accès.</li>
      <li>Le droit de rectification.</li>
      <li>Le droit à l'effacement.</li>
      <li>Le droit de restreindre le traitement.</li>
      <li>Le droit à la portabilité des données.</li>
      <li>Le droit d'opposition.</li>
      <li>
        Les droits relatifs à la prise de décision automatisée et au profilage.
      </li>
    </ul>
    <p>
      Si vous souhaitez exercer ce droit, veuillez nous contacter via les
      coordonnées ci-dessous.
    </p>
    <p>
      En outre, si vous êtes un résident européen, nous notons que nous traitons
      vos informations afin d'exécuter les contrats que nous pourrions avoir
      avec vous (par exemple, si vous passez une commande par le biais du site),
      ou autrement pour poursuivre nos intérêts commerciaux légitimes énumérés
      ci-dessus. En outre, veuillez noter que vos informations pourraient être
      transférées en dehors de l'Europe, y compris au Canada et aux États-Unis.
    </p>

    <h5>Liens vers d'autres sites web :</h5>
    <p>
      Notre site web peut contenir des liens vers d'autres sites web qui ne sont
      pas détenus ou contrôlés par nous. Sachez que nous ne sommes pas
      responsables de ces autres sites web ou des pratiques de confidentialité
      des tiers. Nous vous encourageons à être attentif lorsque vous quittez
      notre site web et à lire les déclarations de confidentialité de chaque
      site web susceptible de collecter des informations personnelles.
    </p>

    <h5>Sécurité de l'information :</h5>
    <p>
      Nous sécurisons les informations que vous fournissez sur des serveurs
      informatiques dans un environnement contrôlé et sécurisé, protégé contre
      tout accès, utilisation ou divulgation non autorisés. Nous conservons des
      garanties administratives, techniques et physiques raisonnables pour nous
      protéger contre tout accès, utilisation, modification et divulgation non
      autorisés des données personnelles sous son contrôle et sa garde.
      Toutefois, aucune transmission de données sur Internet ou sur un réseau
      sans fil ne peut être garantie.
    </p>

    <h5>Divulgation légale :</h5>
    <p>
      Nous divulguerons toute information que nous collectons, utilisons ou
      recevons si la loi l'exige ou l'autorise, par exemple pour nous conformer
      à une citation à comparaître ou à une procédure judiciaire similaire, et
      lorsque nous pensons de bonne foi que la divulgation est nécessaire pour
      protéger nos droits, votre sécurité ou celle d'autrui, enquêter sur une
      fraude ou répondre à une demande du gouvernement.
    </p>

    <h5>Informations de contact :</h5>
    <p>
      Si vous souhaitez nous contacter pour comprendre davantage la présente
      politique ou si vous souhaitez nous contacter concernant toute question
      relative aux droits individuels et à vos informations personnelles, vous
      pouvez envoyer un courriel à
      <a href="mailto:loic@fokus-studio.com">loic@fokus-studio.com</a>.
    </p>
    <div class="closeBox">
        <button @click="closeModal">J'ai compris</button>
    </div>
    <img @click="closeModal" class="exit" src="../assets/icons/cross.svg" alt="">
    
  </div>
</template>

<script>
export default {
  name: "PolitiqueConfidentialite",
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.politiqueContainer {
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  border: 2px solid #1b1b1b;
  background: white;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
top: 0;
    left: 0;
  z-index: 999;
}

h4 {
    color: #333;
  font-size: 16px;
  font-weight: bold;
}
h5{
  color: #333;
  margin-top: 10px;
  font-size: 12px;
}
li {
    color: #555;
  line-height: 1.5;
  margin-bottom: 5px;
    font-size: 10px;
}
p {
  color: #555;
  line-height: 1.5;
  margin-top: 5px;
    font-size: 10px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
.closeBox {
    display: flex;
    justify-content: center;
    width: 100%;
    & button {
    margin-top: 20px;
    padding: 10px 20px;
    background: #1b1b1b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
}
}
.exit {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}


</style>
